import ItemRemovalWarning from 'components/itemRemovalWarning'
import React, { useCallback, useState } from 'react'

const createPromise = (): [Promise<boolean>, (value: boolean) => void] => {
  let resolver: ((value: boolean) => void) | null = null
  const promise = new Promise<boolean>(resolve => {
    resolver = resolve
  })
  if (!resolver) throw new Error('Resolver should be initialized')
  return [promise, resolver]
}

const useConfirmationModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState<string | undefined>()
  const [label, setLabel] = useState<string | React.ReactNode>('')
  const [resolver, setResolver] = useState<((value: boolean) => void) | null>(null)

  const getConfirmation = useCallback(async (text: string | React.ReactNode, title?: string) => {
    setTitle(title)
    setLabel(text)
    setIsOpen(true)
    const [promise, resolve] = createPromise()
    setResolver(() => resolve)
    return promise
  }, [])

  const onClick = useCallback(
    (status: boolean) => {
      setIsOpen(false)
      if (resolver) resolver(status)
    },
    [resolver]
  )

  const ConfirmationModal = useCallback(
    () => (
      <ItemRemovalWarning
        showModal={isOpen}
        title={title}
        setShowModal={() => onClick(false)}
        confirmationMessage={label}
        doConfirmationAction={() => onClick(true)}
      />
    ),
    [isOpen, label, onClick, title]
  )

  return { getConfirmation, ConfirmationModal, resolve: onClick, isOpen }
}

export default useConfirmationModal
