import { captureMessage } from '@sentry/nextjs'
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import Card from 'components/card/Card'
import Loading from 'components/loading/Loading'
import { useAuth } from 'hooks/useAuth'
import { logoutEvent } from 'hooks/useAuth/event'
import { useCallback, useEffect, useState } from 'react'

import EmptyLayout from '../emptyLayout'

const RedirectingState = () => {
  const [timeElapsed, setTimeElapsed] = useState(0)
  const { user, auth0User } = useAuth()

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeElapsed(time => time + 10)
    }, 10 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const logout = useCallback(() => {
    logoutEvent.emit('logout')
  }, [])

  useEffect(() => {
    if (timeElapsed >= 60) {
      logout()
      captureMessage('Login timed out', {
        level: 'error',
        user: {
          email: auth0User?.email || user?.email || 'User email not found',
        },
      })
    }
  }, [auth0User?.email, logout, timeElapsed, user?.email])

  return (
    <EmptyLayout>
      <Card className="flex flex-col justify-center text-center w-[28rem] h-[60vh] m-auto z-20 relative bg-white">
        <Loading className="flex items-center justify-center mb-3" />
        Please wait while we verify user details...
        <Button
          onClick={logout}
          className={cx('w-56 self-center absolute bottom-10 mx-auto', { 'text-neutral-300': timeElapsed < 30 })}
          variant={timeElapsed < 30 ? ButtonVariant.Tertiary : ButtonVariant.Primary}>
          Logout
        </Button>
      </Card>
    </EmptyLayout>
  )
}
export default RedirectingState
