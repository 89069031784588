import { IconProps } from 'assets'

import { baseClass, buttonSize, variants } from './button.config'
import { ButtonSize, ButtonVariant, IconPlacement, RestButtonConfig } from './button.types'

const getVariantClass = (variant: ButtonVariant) => {
  const variantProps = variants[variant]
  if (!variantProps) {
    return []
  }
  return [
    variantProps.background,
    variantProps.border,
    variantProps.text,
    variantProps.hover,
    variantProps.disabled,
    variantProps.focus,
  ]
}

const getButtonClass = (variant: ButtonVariant) => {
  return [...baseClass, ...getVariantClass(variant)]
}

export const VariantMappingToClass: Record<ButtonVariant, string[]> = {
  [ButtonVariant.Primary]: getButtonClass(ButtonVariant.Primary),
  [ButtonVariant.Secondary]: getButtonClass(ButtonVariant.Secondary),
  [ButtonVariant.Tertiary]: getButtonClass(ButtonVariant.Tertiary),
  [ButtonVariant.Prominent]: getButtonClass(ButtonVariant.Prominent),
  [ButtonVariant.AIGradient]: getButtonClass(ButtonVariant.AIGradient),
}

export const getButtonSizeClass = (size?: ButtonSize) => {
  if (size === undefined) return ''

  return buttonSize[size]
}

export const getRestButtonConfig = ({ isFullWidth, iconPlacement, icon }: RestButtonConfig) => ({
  'w-full': isFullWidth,
  'flex-row-reverse': iconPlacement === IconPlacement.Left,
  'flex-row': iconPlacement === IconPlacement.Right,
  'gap-1 items-center flex justify-center': icon,
})

export const getIconSize = (size?: ButtonSize) => {
  if (size === ButtonSize.Large) return 'w-6 h-6'
  if (size === ButtonSize.Medium) return 'w-[1.125rem] h-[1.125rem]'
  if (size === ButtonSize.Small) return 'w-[1.125rem] h-[1.125rem]'
  if (size === ButtonSize.ExtraSmall) return 'w-3 h-3'
  return ''
}

export const getIconClassBasedOnVariant = (
  variant: ButtonVariant,
  size?: ButtonSize,
  isDisabled?: boolean,
  iconCLass?: string
): IconProps => {
  const variantProps = variants[variant]
  if (!variantProps) {
    return {
      className: undefined,
      pathClassName: undefined,
    }
  }

  return {
    className: getIconSize(size),
    pathClassName: iconCLass ? iconCLass : isDisabled ? variantProps.disabledIcon : variantProps.icon,
  }
}
