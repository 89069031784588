import { ROUTES } from 'constants/routes'
import { useAuth } from 'hooks/useAuth'
import useConfirmationModal from 'hooks/useConfirmationModal'
import Router, { useRouter } from 'next/router'
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

type RoutingEventsHandlerType = {
  isDirty: boolean
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
  getExitConfirmation: (message?: string) => Promise<boolean>
}

export const RoutingEventsHandler = React.createContext<RoutingEventsHandlerType>({
  isDirty: false,
  setIsDirty: () => {
    return
  },
  getExitConfirmation: async () => true,
})

const RoutingEventsHandlerProvider = ({ children }: { children: ReactNode }) => {
  const [isDirty, setIsDirty] = useState(false)
  const [blockedUrl, setBlockedUrl] = useState<string>()
  const router = useRouter()
  const { isFetching } = useAuth()

  const { ConfirmationModal, getConfirmation, resolve, isOpen } = useConfirmationModal()

  const getExitConfirmation = useCallback(
    async (message = 'Changes you made may not be saved. Do you want to proceed?') => {
      return await getConfirmation(message)
    },
    [getConfirmation]
  )

  useEffect(() => {
    if (blockedUrl) {
      getExitConfirmation()
        .then(value => {
          if (value) {
            setIsDirty(false)
            router.push(blockedUrl)
          }
        })
        .finally(() => setBlockedUrl(undefined))
    }
  }, [blockedUrl, getExitConfirmation, router])

  useEffect(() => {
    if (isOpen && !isDirty) {
      resolve(true)
    }
  }, [blockedUrl, isDirty, isOpen, resolve, router])

  useEffect(() => {
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      if (isDirty && !isFetching) {
        e.preventDefault()
      }
    }
    const beforeRouteHandler = (url: string) => {
      if (isDirty && !isFetching) {
        setBlockedUrl(url)
        Router.events.emit('routeChangeError')
        throw `Route change to "${url}" was aborted (this error can be safely ignored).`
      }
      const isOrgRoute = url.includes('/org/[orgId]/') || url.includes('/org/%5BorgId%5D/')
      const orgId =
        Router.query?.orgId && !!Number(Router.query?.orgId)
          ? (Router.query?.orgId as string)
          : sessionStorage.getItem('orgId') || localStorage.getItem('orgId')

      if (isOrgRoute && !Number(orgId)) {
        Router.push(ROUTES.HOME)
      }
      if (isOrgRoute && orgId && !!Number(orgId)) {
        Router.push(url.replace('[orgId]', orgId).replace('%5BorgId%5D', orgId))
      }
    }
    isDirty && !isFetching && window.addEventListener('beforeunload', beforeUnloadHandler)
    Router.events.on('routeChangeStart', beforeRouteHandler)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      Router.events.off('routeChangeStart', beforeRouteHandler)
    }
  }, [getExitConfirmation, isDirty, isFetching])

  useEffect(() => {
    if (router.isReady) {
      if (router.query?.orgId) {
        const orgId = router.query.orgId as string

        if (!!Number(orgId) && orgId !== sessionStorage.getItem('orgId')) {
          sessionStorage.setItem('orgId', orgId)
          localStorage.setItem('orgId', orgId)
        } else if (!Number(orgId)) {
          if (sessionStorage.getItem('orgId') || localStorage.getItem('orgId')) {
            router.replace({
              pathname: router.pathname,
              query: { ...router.query, orgId: sessionStorage.getItem('orgId') || localStorage.getItem('orgId') },
            })
          } else {
            router.replace(ROUTES.HOME)
          }
        }
      }
    }
  }, [router])

  const value = useMemo(() => {
    return {
      isDirty,
      setIsDirty,
      getExitConfirmation,
    }
  }, [getExitConfirmation, isDirty])

  return (
    <RoutingEventsHandler.Provider value={value}>
      <ConfirmationModal />
      {children}
    </RoutingEventsHandler.Provider>
  )
}

export const useRoutingHandlerState = (): RoutingEventsHandlerType => React.useContext(RoutingEventsHandler)

export default RoutingEventsHandlerProvider
