import { IconProps } from 'assets'

const Excel = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1893 2.47745H10.4912V1.13477L1.125 2.58039V15.2518L10.4912 16.8651V14.875H16.1893C16.3619 14.8837 16.5309 14.8237 16.6594 14.7082C16.7879 14.5927 16.8654 14.431 16.875 14.2585V3.09339C16.8653 2.92096 16.7877 2.7594 16.6592 2.64399C16.5307 2.52858 16.3618 2.46871 16.1893 2.47745ZM16.2793 14.3608H10.4721L10.4625 13.2983H11.8614V12.0608H10.4518L10.4451 11.3295H11.8614V10.092H10.4344L10.4276 9.36077H11.8614V8.12327H10.4231V7.39202H11.8614V6.15452H10.4231V5.42327H11.8614V4.18577H10.4231V3.06077H16.2793V14.3608Z"
        fill="#20744A"
      />
      <path className={pathClassName} d="M15.0806 4.18457H12.6489V5.42207H15.0806V4.18457Z" fill="#20744A" />
      <path className={pathClassName} d="M15.0806 6.15332H12.6489V7.39082H15.0806V6.15332Z" fill="#20744A" />
      <path className={pathClassName} d="M15.0806 8.12305H12.6489V9.36055H15.0806V8.12305Z" fill="#20744A" />
      <path className={pathClassName} d="M15.0806 10.0928H12.6489V11.3303H15.0806V10.0928Z" fill="#20744A" />
      <path className={pathClassName} d="M15.0806 12.0615H12.6489V13.299H15.0806V12.0615Z" fill="#20744A" />
      <path
        className={pathClassName}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.57017 6.00373L4.7773 5.93455L5.53611 8.02086L6.43274 5.84848L7.63986 5.7793L6.17399 8.74142L7.63986 11.7109L6.36355 11.6248L5.5018 9.3613L4.63949 11.5387L3.46667 11.4352L4.82905 8.8123L3.57017 6.00373Z"
        fill="white"
      />
    </svg>
  )
}

export default Excel
