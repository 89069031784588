import { IconProps } from 'assets'

const Options = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M5.49961 12.4L1.59961 8.5L5.49961 4.6L9.39961 8.5L5.49961 12.4ZM8.99961 22V17C7.98294 16.9167 6.97461 16.7958 5.97461 16.6375C4.97461 16.4792 3.98294 16.2667 2.99961 16L3.49961 14C4.89961 14.3833 6.30378 14.6458 7.71211 14.7875C9.12044 14.9292 10.5496 15 11.9996 15C13.4496 15 14.8788 14.9292 16.2871 14.7875C17.6954 14.6458 19.0996 14.3833 20.4996 14L20.9996 16C20.0163 16.2667 19.0246 16.4792 18.0246 16.6375C17.0246 16.7958 16.0163 16.9167 14.9996 17V22H8.99961ZM5.49961 9.6L6.59961 8.5L5.49961 7.4L4.39961 8.5L5.49961 9.6ZM11.9996 7C11.1663 7 10.4579 6.70833 9.87461 6.125C9.29128 5.54167 8.99961 4.83333 8.99961 4C8.99961 3.16667 9.29128 2.45833 9.87461 1.875C10.4579 1.29167 11.1663 1 11.9996 1C12.8329 1 13.5413 1.29167 14.1246 1.875C14.7079 2.45833 14.9996 3.16667 14.9996 4C14.9996 4.83333 14.7079 5.54167 14.1246 6.125C13.5413 6.70833 12.8329 7 11.9996 7ZM11.9996 14C11.4496 14 10.9788 13.8042 10.5871 13.4125C10.1954 13.0208 9.99961 12.55 9.99961 12C9.99961 11.45 10.1954 10.9792 10.5871 10.5875C10.9788 10.1958 11.4496 10 11.9996 10C12.5496 10 13.0204 10.1958 13.4121 10.5875C13.8038 10.9792 13.9996 11.45 13.9996 12C13.9996 12.55 13.8038 13.0208 13.4121 13.4125C13.0204 13.8042 12.5496 14 11.9996 14ZM11.9996 5C12.2829 5 12.5204 4.90417 12.7121 4.7125C12.9038 4.52083 12.9996 4.28333 12.9996 4C12.9996 3.71667 12.9038 3.47917 12.7121 3.2875C12.5204 3.09583 12.2829 3 11.9996 3C11.7163 3 11.4788 3.09583 11.2871 3.2875C11.0954 3.47917 10.9996 3.71667 10.9996 4C10.9996 4.28333 11.0954 4.52083 11.2871 4.7125C11.4788 4.90417 11.7163 5 11.9996 5ZM17.0496 12L15.3496 9L17.0496 6H20.4496L22.1496 9L20.4496 12H17.0496ZM18.1996 10H19.2996L19.8496 9L19.2996 8H18.1996L17.6496 9L18.1996 10Z"
        fill="#1e40af"
      />
    </svg>
  )
}

export default Options
