import axios from '../../index'
import {
  ArmsLengthOptionType,
  ComparableResultConclusion,
  CreateComparableResultsPayload,
  CreateTermsAndCharacteristicsPayload,
  TermsAndCharacteristics,
  UpdateComparableResultsPayload,
  UpdateControlledTransactionTermsPayload,
  UpdateUncontrolledTransactionTermsFilePayload,
} from './types'

export const getTermsAndCharacteristics = <T extends boolean>(economic_analysis: number) => {
  return axios
    .get<TermsAndCharacteristics<T>[]>(`/api/v1/org/{organization}/transaction/comparables_data/`, {
      params: {
        economic_analysis: economic_analysis,
      },
    })
    .then(res => res.data)
}
export const createTermsAndCharacteristics = <T extends boolean>(payload: CreateTermsAndCharacteristicsPayload) => {
  return axios
    .post<TermsAndCharacteristics<T>>(`/api/v1/org/{organization}/transaction/comparables_data/`, payload)
    .then(res => res.data)
}
export const updateTermsAndCharacteristics = <T extends boolean>({
  id,
  ...payload
}: CreateTermsAndCharacteristicsPayload & {
  id: number
}) => {
  return axios
    .patch<TermsAndCharacteristics<T>>(`/api/v1/org/{organization}/transaction/comparables_data/${id}/`, payload)
    .then(res => res.data)
}

export const updateControlledTransactionTerms = ({ id, ...variables }: UpdateControlledTransactionTermsPayload) => {
  return axios
    .patch(
      `/api/v1/org/{organization}/transaction/economic_analysis/${id}/`,
      {
        ...variables,
      },
      {
        headers: {
          'Content-Type':
            variables.controlled_transaction_terms_file instanceof File ? 'multipart/form-data' : undefined,
        },
      }
    )
    .then(res => res.data)
}

export const deleteControlledOrUncontrolledTransactions = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/transaction/comparables_data/${id}/`)
}

export const updateUncontrolledTransaction = ({ id, ...variables }: UpdateUncontrolledTransactionTermsFilePayload) => {
  return axios
    .patch(
      `/api/v1/org/{organization}/transaction/economic_analysis/${id}/`,
      {
        ...variables,
      },
      {
        headers: {
          'Content-Type':
            variables.uncontrolled_transaction_terms_file instanceof File ? 'multipart/form-data' : undefined,
        },
      }
    )
    .then(res => res.data)
}

export const getArmsLengthRangeTypes = () => {
  return axios
    .get<ArmsLengthOptionType[]>(
      `/api/v1/org/{organization}/transaction/comparable_result_conclusion/get_arms_length_range_types/`
    )
    .then(res => res.data)
}

export const getComparableResultConclusionsList = (year: number) => {
  return axios
    .get<ComparableResultConclusion[]>(`/api/v1/org/{organization}/transaction/comparable_result_conclusion/`, {
      params: {
        year: year,
      },
    })
    .then(res => res.data)
}

export const createComparableResults = ({ data, ...payload }: CreateComparableResultsPayload) => {
  return axios
    .post<ComparableResultConclusion>(`/api/v1/org/{organization}/transaction/comparable_result_conclusion/`, {
      ...data,
      ...payload,
    })
    .then(res => res.data)
}

export const updateComparableResults = ({ id, data, ...payload }: UpdateComparableResultsPayload) => {
  return axios
    .patch<ComparableResultConclusion>(`/api/v1/org/{organization}/transaction/comparable_result_conclusion/${id}/`, {
      ...(data || {}),
      ...payload,
    })
    .then(res => res.data)
}
