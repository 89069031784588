import { IconProps } from 'assets'

const TemplatesSubType = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M14.75 2.09375H4.25C3.83579 2.09375 3.5 2.42954 3.5 2.84375V16.3438C3.5 16.758 3.83579 17.0938 4.25 17.0938H14.75C15.1642 17.0938 15.5 16.758 15.5 16.3438V2.84375C15.5 2.42954 15.1642 2.09375 14.75 2.09375Z"
        stroke="#1E40AF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M6.875 11.8438H12.125"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M6.875 14.0938H9.5"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M7.625 6.96875H11.375"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M9.5 8.84375V5.09375"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TemplatesSubType
