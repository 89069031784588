import axios from '../index'
import { createMasterFileSectionPayload, MasterFileDocument, MasterFileSectionType } from './types'

export const getMasterFileSections = (year?: number) => {
  return axios
    .get<MasterFileSectionType[]>(`/api/v1/org/{organization}/organization/master_file_section/`, {
      params: {
        year: year,
      },
    })
    .then(res => res.data)
}
export const getMasterFileList = (year?: number) => {
  return axios
    .get<MasterFileDocument[]>(`/api/v1/org/{organization}/reports/master_file_document/`, {
      params: {
        year: year,
      },
    })
    .then(res => res.data)
}

export const createMasterFile = (year: number) => {
  return axios
    .get<MasterFileDocument[]>(`/api/v1/org/{organization}/reports/master_file_document/download_master_file/`, {
      params: {
        year: year,
      },
    })
    .then(res => res.data)
}

export const createMasterFileSection = (payload: createMasterFileSectionPayload) => {
  return axios
    .postForm<MasterFileSectionType>(`/api/v1/org/{organization}/organization/master_file_section/`, payload)
    .then(res => res.data)
}
export const updateMasterFileSection = ({
  id,
  ...payload
}: Partial<createMasterFileSectionPayload> & { id: number }) => {
  return axios
    .patchForm<MasterFileSectionType>(`/api/v1/org/{organization}/organization/master_file_section/${id}/`, payload)
    .then(res => res.data)
}

export const deleteMasterFileSection = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/organization/master_file_section/${id}`).then(res => res.data)
}

export const updateMasterFileHtml = ({ html_file, id }: { html_file: File; id: number }) => {
  return axios
    .patchForm(`/api/v1/org/{organization}/reports/master_file_document/${id}/update_master_file_html/`, { html_file })
    .then(res => res.data)
}

export const signOffMasterFile = ({ id }: { id: number }) => {
  return axios
    .patch(`/api/v1/org/{organization}/reports/master_file_document/${id}/sign_off_master_file/`)
    .then(res => res.data)
}

export const deleteMasterFile = ({ id }: { id: number }) => {
  return axios.delete(`/api/v1/org/{organization}/reports/master_file_document/${id}/`).then(res => res.data)
}

export const updateMasterFile = ({ id, name }: { id: number; name: string }) => {
  return axios.patch(`/api/v1/org/{organization}/reports/master_file_document/${id}/`, { name }).then(res => res.data)
}

export const importMasterFileSections = (payload: { year: number; master_file_sections: number[] }) => {
  return axios
    .post<MasterFileSectionType>(
      `/api/v1/org/{organization}/organization/master_file_section/import_master_file_sections/`,
      payload
    )
    .then(res => res.data)
}

export const reorderMasterFileSections = (payload: { year: number; master_file_sections: number[] }) => {
  return axios
    .put<MasterFileSectionType>(`/api/v1/org/{organization}/organization/master_file_section/update_order/`, payload)
    .then(res => res.data)
}
