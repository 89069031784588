import axios from 'api'
import {
  CreateTPFormResponse,
  UpdateTPFormNameResponse,
} from 'api/transactions/createTransaction/types/UploadUserFileProps'

export const createTPForm = (variables: CreateTPFormResponse) => {
  return axios.post(`api/v1/org/{organization}/transaction/transactions/generate_tp_form/`, variables)
}
export const getTPForm = (year: number) => {
  return axios.get(`api/v1/org/{organization}/dms/documents/?type=tp_form&year=${year}`)
}
export const deleteTPForm = (id: number) => {
  return axios.delete(`/api/v1/org/{organization}/dms/documents/${id}/`)
}
export const updateTPFormName = ({ id, variables }: { id: number; variables: UpdateTPFormNameResponse }) => {
  return axios.patch(`/api/v1/org/{organization}/dms/documents/${id}/`, variables)
}
