import { IconsType } from 'assets/types'
import Button from 'components/button'
import { ROUTES } from 'constants/routes'
import Router from 'next/router'

import { RightSectionComponentProps } from './types/appLayout.types'

export const getRightComponent = ({ activeLink }: RightSectionComponentProps) => {
  // TODO: Still need to work on this component
  if (activeLink === ROUTES.TEMPLATE_MANAGEMENT) {
    {
      return <Button icon={IconsType.plus}>Create Template</Button>
    }
  }
  return null
}

export const getFormattedPath = (path: string) => {
  if (path === '[id]') {
    if (Router.pathname === `${ROUTES.TRANSACTION_MANAGEMENT}/[id]`) {
      return Router.query.txnName
    }
  }
  return path.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
}
