export const logoStyles: React.CSSProperties = {
  objectFit: 'cover',
}

export const LOGO_CONFIG = {
  height: 57,
}

export const ITEM_HEIGHT = 48

export const PAPER_STYLE = {
  maxHeight: ITEM_HEIGHT * 4.5,
  minWidth: '20ch',
}

export const ICON_STYLE: React.CSSProperties = {
  position: 'absolute',
  left: '85%',
  bottom: '30%',
}
