import { IconProps } from 'assets'
import React from 'react'

const FileCopy = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="file_copy">
        <path
          className={pathClassName}
          id="Vector"
          d="M19 19H8C7.45 19 6.97917 18.8042 6.5875 18.4125C6.19583 18.0208 6 17.55 6 17V3C6 2.45 6.19583 1.97917 6.5875 1.5875C6.97917 1.19583 7.45 1 8 1H14.175C14.4417 1 14.6958 1.05 14.9375 1.15C15.1792 1.25 15.3917 1.39167 15.575 1.575L20.425 6.425C20.6083 6.60833 20.75 6.82083 20.85 7.0625C20.95 7.30417 21 7.55833 21 7.825V17C21 17.55 20.8042 18.0208 20.4125 18.4125C20.0208 18.8042 19.55 19 19 19ZM19 8H15.5C15.0833 8 14.7292 7.85417 14.4375 7.5625C14.1458 7.27083 14 6.91667 14 6.5V3H8V17H19V8ZM4 23C3.45 23 2.97917 22.8042 2.5875 22.4125C2.19583 22.0208 2 21.55 2 21V8C2 7.71667 2.09583 7.47917 2.2875 7.2875C2.47917 7.09583 2.71667 7 3 7C3.28333 7 3.52083 7.09583 3.7125 7.2875C3.90417 7.47917 4 7.71667 4 8V21H14C14.2833 21 14.5208 21.0958 14.7125 21.2875C14.9042 21.4792 15 21.7167 15 22C15 22.2833 14.9042 22.5208 14.7125 22.7125C14.5208 22.9042 14.2833 23 14 23H4Z"
          fill="#1E3A8A"
        />
      </g>
    </svg>
  )
}

export default FileCopy
