import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationDetails } from 'api/organization/types'

// create a slice
export const appSlice = createSlice({
  name: 'app',
  initialState: {
    organisation: null as OrganizationDetails | null,
    isSidebarOpen: false,
    isEditTransactionMode: false,
    year: new Date().getFullYear() - 1,
    userAccess: {
      addUserModalState: { open: false },
      importUserModal: { open: false },
      exportUserModal: { open: false },
    },

    refetchGlobalSearch: false,
  },
  reducers: {
    resetOrganisation: (state, payload) => {
      if (payload) {
        state.organisation = payload.payload
      } else {
        state.organisation = null
      }
    },
    updateSidebar: (state, payload) => {
      state.isSidebarOpen = payload.payload
    },
    updateAddUserModalState: (state, payload) => {
      state.userAccess.addUserModalState = payload.payload
    },
    updateImportUserModalState: (state, payload) => {
      state.userAccess.importUserModal.open = payload.payload
    },
    updateExportUserModalState: (state, payload) => {
      state.userAccess.exportUserModal.open = payload.payload
    },
    updateYear: (state, payload) => {
      state.year = payload.payload
    },
    setGlobalSearchRefetch: (state, payload: PayloadAction<boolean>) => {
      state.refetchGlobalSearch = payload.payload
    },
  },
})

// export the action
export const appActions = appSlice.actions
